import Link from "next/link";
import { Button, Input, Text, Container, Row, Spacer } from "@nextui-org/react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";

const SignForm = ({
  isLogin,
  errorMessage,
  onSubmit,
  setHcaptchaToken,
  isDisabled,
}) => {
  const [password, setPassword] = useState("");
  return (
    <form onSubmit={onSubmit} className="submission">
      {/* Surely there's a better way? */}
      <Container css={{ maxWidth: 400 }}>
        <Row>
          <Input
            type="text"
            name="username"
            label="Email address"
            fullWidth="true"
            required
          />
        </Row>
        <Spacer y={0.5} />
        <Row>
          <Input.Password
            type="password"
            name="password"
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            fullWidth="true"
            required
          />
        </Row>
        <Spacer y={0.5} />
        {!isLogin && (
          <>
            <Row css={{ display: "block" }}>
              <PasswordStrengthBar password={password} />
              {/* lol. */}
              <Spacer y={-0.2} />
            </Row>
            <Row>
              <Input.Password
                type="password"
                name="rpassword"
                label="Repeat password"
                fullWidth="true"
                required
              />
            </Row>
            <Spacer y={0.5} />
          </>
        )}
        {process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY && (
          <>
            <Row>
              <HCaptcha
                sitekey={process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY}
                onVerify={setHcaptchaToken}
              />
            </Row>
            <Spacer y={0.5} />
          </>
        )}
        {isLogin ? (
          <>
            <Spacer y={0.5} />
            <Row>
              <Button type="submit" disabled={isDisabled}>
                Login
              </Button>
            </Row>
            <Spacer y={1} />
            <Row>
              <Link href="/signup">
                <a>I don't have an account</a>
              </Link>
            </Row>
            <Spacer y={0.5} />
            <Row>
              <Link href="/requestpasswordreset">
                <a>I forgot my password</a>
              </Link>
            </Row>
            <Spacer y={0.5} />
          </>
        ) : (
          <>
            <Spacer y={1} />
            <Row>
              <Button type="submit" disabled={isDisabled}>
                Signup
              </Button>
            </Row>
            <Spacer y={1} />
            <Row>
              <Link href="/login">
                <a>I already have an account</a>
              </Link>
            </Row>
            <Spacer y={0.5} />
          </>
        )}

        {errorMessage && (
          <Row>
            <Text color="warning">{errorMessage}</Text>
          </Row>
        )}
      </Container>
    </form>
  );
};

export default SignForm;
