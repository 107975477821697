import Head from 'next/head';
import { useState } from "react";
import { useRouter } from "next/router";
import { useUser } from "../lib/hooks";
import Layout from "../components/layout";
import SignForm from "../components/SignForm";
import Banner from "../components/Banner.js";

const Login = () => {
  useUser({ redirectTo: "/", redirectIfFound: true });

  const [errorMsg, setErrorMsg] = useState("");
  const [hcaptchaToken, setHcaptchaToken] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const router = useRouter();
  const { reset, resetstarted, signedup } = router.query;

  async function handleSubmit(e) {
    setIsDisabled(true);
    e.preventDefault();

    if (errorMsg) setErrorMsg("");

    const body = {
      username: e.currentTarget.username.value,
      password: e.currentTarget.password.value,
      hcaptchaToken,
    };

    const res = await fetch("/api/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    if (res.status === 200) {
      window.location = "/?loggedin";
    } else {
      setIsDisabled(false);
      const data = await res.json();
      setErrorMsg(data.error || "Sorry, something went wrong.");
    }
  }

  return (
    <Layout>
      {signedup !== undefined && (
        <Banner type="success">
          Thanks for signing up! You should get an email with a link to activate
          your account soon.
        </Banner>
      )}
      {reset !== undefined && (
        <Banner type="success">
          Your password has been reset. You may now use it to log in.
        </Banner>
      )}
      {resetstarted !== undefined && (
        <Banner type="success">
          Check your email for a password reset link!
        </Banner>
      )}
      <div className="login">
        <SignForm
          isLogin
          errorMessage={errorMsg}
          onSubmit={handleSubmit}
          setHcaptchaToken={setHcaptchaToken}
          isDisabled={isDisabled}
        />
      </div>
    </Layout>
  );
};

export default Login;
